// @flow

import React from 'react';
import { connect } from 'react-redux';
import { useParams, Navigate } from 'react-router-dom';
import userIdActions from '../../../actions/userIdActions';
import type { PageProps } from '../../../lib/types';

const StartMP = ({ setUserId }: PageProps): React.Element<*> => {
  const { friendly_id } = useParams();

  setUserId(friendly_id);

  return (
    <Navigate to="/splash" />
  );
};

const mapDispatchToProps = userIdActions;

export default connect(null, mapDispatchToProps)(StartMP);
