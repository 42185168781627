// @flow

import React from 'react';
import { connect } from 'react-redux';
import StarLegend from './StarLegend';
import RecSection from './RecSection';
import { RegularTable, MobileTable } from './Tables';
import ConditionalTableTab from './ConditionalTableTab';
import { RegularRibbon, MobileFakeRibbon, RegularFakeRibbon } from '../../shared/ribbon/Ribbon';
import Rainbow from '../../shared/rainbow/Rainbow';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import ModalWithOverlay from '../../shared/modal/ModalWithOverlay';
import { FakeNavButtonContainer } from '../sideEffects/SideEffects';
import isAnyModalVisibleActions from '../../../actions/isAnyModalVisibleActions';
import type { PageProps, State } from '../../../lib/types';
import './CompareMethods.scss';

type CompareMethodsState = {
  method1s: [string | null],
  isModalOn: boolean,
  screenSize: string,
  activeTab: null | number,
};

type CompareMethodsProps = PageProps & {
  setModalInvisible: () => {},
  setModalVisible: () => {},
};

const bcNamesEnglish = [
  'sterilization',
  'hormonalIud',
  'copperIud',
  'implant',
  'shot',
  'ring',
  'patch',
  'pill',
  'diaphragm',
  'maleCondom',
  'femaleCondom'
];

class CompareMethods extends React.Component<CompareMethodsProps, CompareMethodsState> {
  _modal_heading: ?HTMLHeadingElement;
  _regular_tab0: ?HTMLTableCellElement;
  _regular_tab1: ?HTMLTableCellElement;
  _mobile_tab0: ?HTMLTableCellElement;
  _mobile_tab1: ?HTMLTableCellElement;

  constructor(props: CompareMethodsProps) {
    super(props);
    this.state = {
      isModalOn: false,
      activeTab: null,
      methods: [null, null]
    };
  }

  modalHeadingRef = (c: ?HTMLHeadingElement) => { this._modal_heading = c; }
  regularTabButton0Ref = (c: ?HTMLTableCellElement) => { this._regular_tab0 = c; }
  regularTabButton1Ref = (c: ?HTMLTableCellElement) => { this._regular_tab1 = c; }
  mobileTabButton0Ref = (c: ?HTMLTableCellElement) => { this._mobile_tab0 = c; }
  mobileTabButton1Ref = (c: ?HTMLTableCellElement) => { this._mobile_tab1 = c; }

  modalClickHandler = (callback: void => void) => {
    const { isModalOn } = this.state;
    const { setModalVisible, setModalInvisible } = this.props;
    if (isModalOn) setModalInvisible();
    else setModalVisible();
    this.setState({ isModalOn: !isModalOn }, callback);
  }

  selectMethodHandler = (activeTab: number) => {
    this.setState({ activeTab });
    this.modalClickHandler(() => { this._modal_heading.focus(); });
  }

  removeMethodHandler = (currentTab: number) => {
    const { methods } = this.state;
    const newMethodState = [...methods];
    newMethodState[currentTab] = null;
    this.setState({ methods: newMethodState });
  }

  iconClickHandler = (name: string) => {
    const { setModalInvisible } = this.props;
    const { activeTab, methods } = this.state;
    const newMethodState = [...methods];
    newMethodState[activeTab] = name;
    const width = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;
    const currentTab = activeTab;

    setModalInvisible();
    this.setState({
      methods: newMethodState,
      isModalOn: false,
    }, () => {
      if (width < 600) this[`_mobile_tab${currentTab}`].focus();
      else this[`_regular_tab${currentTab}`].focus();
    });
  }

  render() {
    const { isModalOn, methods, activeTab } = this.state;
    const { navLinks, isAnyModalVisible, locale, lexicon, sharedLexicon } = this.props;
    const iconNames = bcNamesEnglish.map((name: string): string => (sharedLexicon.get(`methodNames.${name}`)));
    const commonConditionalTabsProps = {
      lexicon,
      sharedLexicon,
      isButtonInTabDisabled: isModalOn,
      selectMethodHandler: this.selectMethodHandler,
      removeMethodHandler: this.removeMethodHandler,
      methods,
      locale,
    };

    return (
      <>
        <Headings
          str={lexicon.get('headings')}
          ariaHidden={isAnyModalVisible}
          isAutoFocusing
        />
        <MarkdownBlock str={lexicon.get('paragraphs')} ariaHidden={isAnyModalVisible} />
        <p className="screen-reader-only">{lexicon.get('deselect')}</p>
        <RegularRibbon
          str={sharedLexicon.get('navigation.backToRecsText')}
          to={`/${locale}/${navLinks[0]}`}
          isHidden={isModalOn}
        />
        <RegularTable
          methods={methods}
          lexicon={lexicon}
          isAriaHiddenOn={isModalOn}
        >
          <ConditionalTableTab
            index={0}
            thRef={this.regularTabButton0Ref}
            {...commonConditionalTabsProps}
          />
          <ConditionalTableTab
            index={1}
            thRef={this.regularTabButton1Ref}
            {...commonConditionalTabsProps}
          />
        </RegularTable>
        <MobileTable
          methods={methods}
          lexicon={lexicon}
          isAriaHiddenOn={isModalOn}
        >
          <ConditionalTableTab
            index={0}
            thRef={this.mobileTabButton0Ref}
            {...commonConditionalTabsProps}
          />
          <ConditionalTableTab
            index={1}
            thRef={this.mobileTabButton1Ref}
            {...commonConditionalTabsProps}
          />
        </MobileTable>
        <div className="nav-buttons-container">
          <MobileFakeRibbon
            str={sharedLexicon.get('navigation.backToRecsText')}
            to={`/${locale}/${navLinks[0]}`}
            isHidden={isModalOn}
          />
          <RegularFakeRibbon
            str={sharedLexicon.get('navigation.backToRecsText')}
            to={`/${locale}/${navLinks[0]}`}
            isHidden={isModalOn}
          />
        </div>
        <ModalWithOverlay
          closeBox={lexicon.get('closeBox')}
          isModalOn={isModalOn}
          id="compare-methods-select"
          clickHandler={() => {
            const currentTab = activeTab;
            this.modalClickHandler(() => {
              this[`_regular_tab${currentTab}`].focus();
            });
          }}
        >
          <Rainbow />
          <div className="modal-content">
            <Headings
              str={lexicon.get('modal.headings')}
              externalHeading1Ref={this.modalHeadingRef}
              id="compare-methods-select-modal-title"
            />
            <MarkdownBlock str={lexicon.get('modal.introText')} ariaHidden />
            <RecSection
              iconNamesEnglish={bcNamesEnglish}
              iconNames={iconNames}
              iconClickHandler={this.iconClickHandler}
              lexicon={lexicon}
            />
            <StarLegend
              headingText={lexicon.get('modal.explanation_title')}
              listArr={lexicon.get('modal.explanations')}
              ariaHidden
            />
            <FakeNavButtonContainer
              sharedLexicon={sharedLexicon}
              clickHandler={() => {
                const currentTab = activeTab;
                this.modalClickHandler(() => {
                  this[`_mobile_tab${currentTab}`].focus();
                });
              }}
            />
          </div>
        </ModalWithOverlay>
      </>
    );
  }
}

const mapStateToProps = ({ isAnyModalVisible }: State): { isAnyModalVisible: boolean } => ({ isAnyModalVisible });
const mapDispatchToProps = isAnyModalVisibleActions;

export default connect(mapStateToProps, mapDispatchToProps)(CompareMethods);
