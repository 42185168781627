// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Brackets, IconBar, ResultBar, MobileBracketsAndIconBars } from '../../shared/IconBarParts';
import { Headings, MarkdownBlock } from '../../shared/textComponents/TextComponents';
import ModalWithOverlay from '../../shared/modal/ModalWithOverlay';
import CalendarBar from './CalendarBar';
import isAnyModalVisibleActions from '../../../actions/isAnyModalVisibleActions';
import type { PageProps, State } from '../../../lib/types';
import '../../shared/IconArrayPage.scss';
import './HowOften.scss';

type HowOftenState = {
  selectedIconNum: number | null,
};

type HowOftenProps = PageProps & {
  setModalInvisible: () => {},
  setModalVisible: () => {},
};

const methods = [
  'femaleCondom',
  'maleCondom',
  'diaphragm',
  'pill',
  'patch',
  'ring',
  'shot',
  'implant',
  'iud',
  'sterilization'
];

const calendarBarGenerator = (num: number | null): React.Node => {
  if (num === null) return null;

  const activeMethodClassName = methods[num];
  if (num < 6) return <div className={`calendar ${activeMethodClassName}`} />;
  if (num === 6) {
    return (
      <CalendarBar
        activeMethodClassName={activeMethodClassName}
        freq={3}
        duration={12}
        isMonthBar
      />
    );
  }
  if (num === 7) {
    return (
      <CalendarBar
        activeMethodClassName={activeMethodClassName}
        freq={5}
        duration={7}
      />
    );
  }
  if (num === 8) {
    return (
      <CalendarBar
        activeMethodClassName={activeMethodClassName}
        freq={12}
        duration={13}
      />
    );
  }
  return (
    <CalendarBar
      activeMethodClassName={activeMethodClassName}
      freq={100}
      duration={10}
      isInfinity
    />
  );
};

const mobileConfig = [3, 4, 3];

class HowOften extends React.Component<HowOftenProps, HowOftenState> {
  _modalParagraph: HTMLParagraphElement;

  constructor(props: HowOftenProps) {
    super(props);
    this.state = { selectedIconNum: null };
    this.modalParagraphRef = (c: HTMLHeadingElement) => { this._modalParagraph = c; };
    this.mobileButtonRefs = [];
    methods.forEach((_: number, i: number) => {
      this.mobileButtonRefs[i] = React.createRef();
    });
  }

  componentDidMount() {
    if (window.innerWidth > 720) setTimeout(() => { this.setState({ selectedIconNum: 0 }); }, 10);
  }

  bcIconClickHandler = (num: number | null, mode: string) => {
    const { setModalInvisible, setModalVisible } = this.props;
    const { selectedIconNum: prevSelectedIconNum } = this.state;
    this.setState({ selectedIconNum: num });

    if (mode === 'mobile') {
      if (num === null) {
        setModalInvisible();
        setTimeout(() => { this.mobileButtonRefs[prevSelectedIconNum].current.focus(); }, 10);
      } else {
        setModalVisible();
        setTimeout(() => { this._modalParagraph.focus(); }, 10);
      }
    }
  }

  render() {
    const { isAnyModalVisible, locale, lexicon, sharedLexicon } = this.props;
    const { selectedIconNum } = this.state;

    const isModalOn = selectedIconNum !== null;
    const heading = selectedIconNum === null ? '' : `# ${sharedLexicon.get(`methodNames.${methods[selectedIconNum]}`)}`;
    const fullParagraph = selectedIconNum === null ? '' : lexicon.get(`bcResults.${selectedIconNum}`);
    const commonPropsForIconResult = {
      iconNameArr: methods,
      selectedIconNum,
      isAnyModalVisible,
      sharedLexicon,
    };

    return (
      <>
        <div className="bc-plain-texts-container" aria-hidden={isAnyModalVisible}>
          <Headings isAutoFocusing str={lexicon.get('headings')} ariaHidden={isAnyModalVisible} />
          <MarkdownBlock str={lexicon.get('paragraphs')} ariaHidden={isAnyModalVisible} />
          <h2 className="screen-reader-only" aria-hidden={isAnyModalVisible}>{lexicon.get('hiddenText')}</h2>
        </div>
        <div className="bc-bars-and-brackets-container regular">
          <Brackets rangeArr={lexicon.get('ranges')} />
          <IconBar
            clickHandler={this.bcIconClickHandler}
            locale={locale}
            resultArr={lexicon.get('bcResults')}
            {...commonPropsForIconResult}
          />
          <ResultBar
            resultArr={lexicon.get('bcResults')}
            {...commonPropsForIconResult}
          />
          { calendarBarGenerator(selectedIconNum) }
        </div>
        <MobileBracketsAndIconBars
          rangeArr={lexicon.get('ranges')}
          clickHandler={(num: number | null) => { this.bcIconClickHandler(num, 'mobile'); }}
          mobileConfig={mobileConfig}
          mobileButtonRefs={this.mobileButtonRefs}
          resultArr={lexicon.get('bcResults')}
          {...commonPropsForIconResult}
        />
        <div className="mobile mobile-graphics-container">
          <ModalWithOverlay
            isModalOn={isModalOn}
            clickHandler={() => { this.bcIconClickHandler(null, 'mobile'); }}
            closeBox={lexicon.get('closeBox')}
          >
            <Headings externalHeading1Ref={this.modalParagraphRef} str={heading} />
            <MarkdownBlock str={fullParagraph} />
            {calendarBarGenerator(selectedIconNum)}
            <div className="reserved-space" />
          </ModalWithOverlay>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ isAnyModalVisible }: State): { isAnyModalVisible: boolean } => ({ isAnyModalVisible });
const mapDispatchToProps = isAnyModalVisibleActions;

export default connect(mapStateToProps, mapDispatchToProps)(HowOften);
