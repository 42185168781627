// @flow

import React from 'react';
import { connect } from 'react-redux';
import Measure from 'react-measure';
import IconButton from './IconButton';
import { hyphenReplacerFunc } from '../../../lib/utils';
import type { Dispatch, State } from '../../../lib/types';

type IconContainerWithMeasureProps = {
  isAnyModalVisible: boolean,
  isActive: boolean,
  positionStr: string,
  isFloatingBoxActive: boolean,
  renderInfoBox: ({}) => React.Element<*>,
  toggleActiveRec: Dispatch,
  iconNameEnglish: string,
  iconName: string,
  index: number,
  actionHandler: React.SyntheticEvent<HTMLSpanElement> => void,
  iconExplanation: string,
  ariaHidden: boolean,
  locale: string,
  sectionText: string,
};

type StyleConfig = {screenWidth: number, iconWidth: number };

type MeasureProps = {
  measure: void => void,
  contentRect: {},
  measureRef: HTMLSpanElement => void,
};

const styleConfig = [
  { screenWidth: 0, iconWidth: 96, row: 3 },
  { screenWidth: 380, iconWidth: 112, row: 3 },
  { screenWidth: 719, iconWidth: 141, row: 4 },
];

class IconContainerWithMeasure extends React.Component<IconContainerWithMeasureProps> {
  render() {
    const {
      index, iconNameEnglish, iconName, isActive, positionStr, isFloatingBoxActive, renderInfoBox,
      toggleActiveRec, actionHandler, iconExplanation, ariaHidden, isAnyModalVisible, locale, sectionText
    } = this.props;

    return (
      <div className="icon-container" key={iconNameEnglish}>
        <Measure offset >
          {
            ({ measure, measureRef, contentRect }: MeasureProps): React.Fragment => {
              // console.log('contentRect', contentRect);
              const clickHandlerWithMeasure = (e: React.SyntheticEvent<HTMLSpanElement>) => { actionHandler(e); measure(); e.stopPropagation(); };
              const keyPressHandlerWithMeasure = (evt: React.SyntheticEvent<HTMLSpanElement>) => {
                const keyNum = (evt.which) ? evt.which : evt.keyCode;

                switch (keyNum) {
                  case 13: // enter
                    clickHandlerWithMeasure(evt);
                    break;
                  case 32: // space
                    clickHandlerWithMeasure(evt);
                    break;
                  default:
                    break;
                }
              };

              const style = {};
              const arrowClassName = `${positionStr} ${isFloatingBoxActive ? '' : 'no-display'}`;
              // if (Object.keys(contentRect.offset).length) console.log(`contentRect for ${iconNameEnglish}`, contentRect);
              // if (positionStr === 'info-box-down-arrow') {
              //   style.bottom = document.body.clientWidth > 499 ? 23 : 26;
              // } else if (positionStr === 'info-box-up-arrow') {
              //   style.top = 23;
              // }
              styleConfig.forEach((config: StyleConfig) => {
                if (document.body.clientWidth > config.screenWidth && contentRect.offset.left) {
                  if (index % config.row === 0) style.left = contentRect.offset.left * -1;
                  else if (index % config.row === config.row - 1) {
                    style.right = contentRect.offset.left - config.iconWidth;
                    style.left = 'auto';
                  } else {
                    const relativeRight = ((contentRect.offset.left - (config.iconWidth - contentRect.offset.width) / 2) / document.body.clientWidth) * 100;

                    style.left = 'auto';
                    style.right = `${relativeRight}vw`;
                    style.transform = 'translateX(50%)';
                  }
                }
              });
              // console.log(`style for ${iconNameEnglish}`, style)

              return (
                <>
                  <IconButton
                    iconNameEnglish={iconNameEnglish}
                    iconName={iconName}
                    isActive={isActive}
                    clickHandler={toggleActiveRec}
                    ariaHidden={ariaHidden}
                    sectionText={sectionText}
                  >
                    <span
                      role="button"
                      onClick={clickHandlerWithMeasure}
                      onKeyDown={keyPressHandlerWithMeasure}
                      tabIndex={isAnyModalVisible ? null : '0'}
                      aria-hidden={isAnyModalVisible}
                      ref={measureRef}
                      className="question-mark"
                    >
                      <span className={`${arrowClassName} span-arrow`} />
                      { hyphenReplacerFunc(iconExplanation, locale).replace(/\*/g, '') }
                      { renderInfoBox(style) }
                    </span>
                  </IconButton>
                </>
              );
            }
          }
        </Measure>
      </div>
    );
  }
}

const mapStateToProps = ({ isAnyModalVisible }: State): {} => ({ isAnyModalVisible });

export default connect(mapStateToProps)(IconContainerWithMeasure);
