// @flow

import React from 'react';

type IconButtonProps = {
  iconNameEnglish: string,
  iconName: string,
  isActive: boolean,
  clickHandler: string => void,
  newline?: boolean,
  children?: React.Node,
  ariaHidden: boolean,
  ariaText: string,
  sectionText: string,
};

const IconButton = ({
  ariaText = '',
  ariaHidden,
  iconNameEnglish,
  iconName,
  isActive = false,
  clickHandler,
  children = null,
  newline = false,
  sectionText
}: IconButtonProps): React.Element<'button'> => {
  const methodClassName = iconNameEnglish;
  const text = newline ? `${iconName}\n` : iconName;
  const extraClass = isActive ? 'active' : '';

  const keyPressHandler = (evt: React.SyntheticEvent<HTMLSpanElement>) => {
    const keyNum = (evt.which) ? evt.which : evt.keyCode;

    switch (keyNum) {
      case 13: // enter
        clickHandler(iconNameEnglish);
        break;
      case 32: // space
        clickHandler(iconNameEnglish);
        break;
      default:
        break;
    }
  };

  return (
    <div key={iconNameEnglish} className={`rec-icon-container ${extraClass}`} >
      <div
        aria-checked={isActive}
        role="checkbox"
        tabIndex={ariaHidden ? null : '0'}
        aria-label={`${text}, ${ariaText}`}
        onClick={() => { clickHandler(iconNameEnglish); }}
        onKeyDown={keyPressHandler}
        className={`icon ${methodClassName}`}
        id={`${sectionText}-checkbox-${methodClassName}-box`}
      />
      <label htmlFor={`checkbox-for-${methodClassName}-box`} id={`checkbox-for-${methodClassName}`} aria-hidden>
        {text}
        {children}
      </label>
    </div>
  );
};

IconButton.defaultProps = {
  children: null,
  newline: false,
};

export default IconButton;
