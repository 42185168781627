// @flow

import React from 'react';
import { Lexicon } from '@nitidbit/lexicon';
import { connect } from 'react-redux';
import IconButton from '../recommendations/IconButton';
import { sectionOrderArr } from '../recommendations/Recommendations';
import { RecsObjTransformFunc } from '../../../lib/utils';
import { RecEngineOutput, State } from '../../../lib/types';
import BlueStar from './blue-star.svg';
import OrangeStar from './orange-star.svg';
import GreenStar from './green-star.svg';

type RecSectionProps = {
  iconNames: Array<string>,
  iconNamesEnglish: Array<string>,
  recs: Object,
  iconClickHandler: (number, string) => void,
  lexicon: Lexicon,
};

const StarImageArray = [
  <img src={BlueStar} alt="blue-star" className="star blue-star" />,
  <img src={GreenStar} alt="green-star" className="star green-star" />,
  <img src={OrangeStar} alt="orange-star" className="star orange-star" />,
];

const screenReaderSanitizer = (str: string): string => (str.replace(/_|\*/gi, ' '));

const RecSection = ({
  iconNamesEnglish,
  iconNames,
  iconClickHandler,
  recs,
  lexicon,
}: RecSectionProps): React.Node => (
  <div className="rec-section-container">
    {
      iconNamesEnglish.map((iconNameEnglish: string, index: number): React.Element<'IconButton'> => {
        const explanations = lexicon.get('modal.explanations');
        const iconName = iconNames[index];
        let ariaText = '';
        if (recs[sectionOrderArr[0]].includes(iconNameEnglish)) ariaText += screenReaderSanitizer(explanations[0]);
        if (recs[sectionOrderArr[1]].includes(iconNameEnglish)) ariaText += screenReaderSanitizer(explanations[1]);
        if (recs[sectionOrderArr[2]].includes(iconNameEnglish)) ariaText += screenReaderSanitizer(explanations[2]);

        return (
          <IconButton
            key={iconNameEnglish}
            iconNameEnglish={iconNameEnglish}
            iconName={iconName}
            clickHandler={iconClickHandler}
            ariaText={ariaText}
            newline
          >
            <span className="stars-container">
              { recs[sectionOrderArr[0]].includes(iconNameEnglish) && StarImageArray[0]}
              { recs[sectionOrderArr[1]].includes(iconNameEnglish) && StarImageArray[1]}
              { recs[sectionOrderArr[2]].includes(iconNameEnglish) && StarImageArray[2]}
            </span>
          </IconButton>
        );
      })
    }
  </div>
);
const mapStateToProps = ({ surveyStateMBC }: State): RecEngineOutput => ({ recs: RecsObjTransformFunc(surveyStateMBC) });
export default connect(mapStateToProps)(RecSection);
