// @flow

import React from 'react';
import { MarkdownBlock } from '../../shared/textComponents/TextComponents';
import './HelpfulResources.scss';

type Props = {
  title: string,
  resources: Array<string>,
};

const HelpfulResources = ({ title, resources }: Props): React.Element<'div'> => (
  <div className="item HelpfulResources">
    <h2>{title}</h2>
    <ul>
      { resources.map((resource: string): React.Element<'li'> => (
        <li key={resource}>
          <MarkdownBlock str={resource} />
        </li>
      ))}
    </ul>
  </div>
);

export default HelpfulResources;
